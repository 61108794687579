import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Spinner, Button, Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "../Common/Skeleton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const AvailDeal = () => {
  const userState = useSelector(
    (state) => state?.merchantLogin.userData.result
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const [dealData, setDealData] = useState({});
  const [loader, setLoader] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [colSpanValue, setColSpanValue] = useState(4);
  const isCalledRef = useRef(false);

  const [availDealLoader, setAvailDealLoader] = useState(false);

  useEffect(() => {
    if (!isCalledRef.current) {
      getDealDetails();
      isCalledRef.current = true;
    }
  }, [state]);

  const getDealDetails = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/user/v1/getUserCartByScanValueId",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          scanValue: state,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("Deal Data", response);
        if (response.code === 200) {
          setDealData(response.result);
          let total = 0;
          let discount = 0;
          response.result.data.map((row) => {
            total += parseFloat(row.actualAmount * row.quantity);
            discount += row.loyaltySpend * 1;
          });
          setTotalDiscount(discount);
          setTotalPrice(total);
        } else if (response.code === 500) {
          toast.error("Invalid QR or Deal Code");
          navigate("/dashboard/scan-new-deal");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err While making request", err);
        setLoader(false);
      });
  };

  const availDealMerchant = async () => {
    setAvailDealLoader(true);
    console.log(userState.merchantId, userState.userId);
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/user/v1/TransactionCtrl/merchantScanUpdate",
      {
        method: "POST",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": `jwt ${userState.token}`,
         },
        body: JSON.stringify({
          merchantId: userState.userId,
          scanValue: state,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.code === 200) {
          console.log(response);
          navigate("/dashboard/merchant-transactions");
        }
        setAvailDealLoader(false);
      })
      .catch((err) => {
        console.log("Err While making request", err);
        setAvailDealLoader(false);
      });
  };

  return (
    <div className="outletPadding">
      <Container>
        <Row className="hustify-content-center">
          <h4>Scanned Deal Details</h4>
        </Row>

        <Row>
            <Col className="mt-3 mb-3" md={3}>
            <Button className="primaryBtn" onClick={ () => window.history.back() }>
                Back
            </Button>
            </Col>
        </Row>

        {loader ? (
          <Spinner />
        ) : (
          <div className="deal-container">
            <div className="product-list">
              {dealData?.data && dealData.data.length > 0 ? (
                dealData.data.map((row, index) => (
                  <div className="product-card" key={index}>
                    <img
                      src={row.image[0]}
                      alt={row.en.productName}
                      className="product-image"
                    />
                    <div className="product-info">
                      <h3 className="product-name">{row.en.productName}</h3>
                      <div className="price-details">
                        <span className="price">₹{row.actualAmount}</span>
                        {/* <span className="actual-amount">₹{row.price}</span> */}
                      </div>
                      {/* <div className="discount">Save ₹{row.saveAmount}</div> */}
                    </div>
                  </div>
                ))
              ) : (
                <p>No data found</p>
              )}
            </div>
            <hr />
            <div className="show-price">
              <span>Total Price</span>
              <span>{totalPrice}</span>
            </div>
            <div className="show-price">
              <span>Discount</span>
              <span>- {totalDiscount}</span>
            </div>
            <div className="show-price final-price">
              <span>Final Price</span>
              <span>{dealData.final_price}</span>
            </div>
          </div>
        )}

        {availDealLoader ? (
          <Spinner />
        ) : !dealData.isMerchantScans ? (
          <center className="m-4">
            <Button
              className="primaryBtn mt-2"
              onClick={() => availDealMerchant()}
            >
              Avail Deal
            </Button>
          </center>
        ) : null}
      </Container>
    </div>
  );
};

export default AvailDeal;
