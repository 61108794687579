import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Spinner, Button, Col, Card } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const MerchantTransactions = () => {

  const navigate = useNavigate()
  const userState = useSelector(
    (state) => state?.merchantLogin.userData.result
  );
  const [transactionList, setTranscationList] = useState([]);
  const [loader, setLoader] = useState(false);
  const isCalledRef = useRef(false);

  useEffect(() => {
    if (!isCalledRef.current) {
      getMerchantTransaction();
      isCalledRef.current = true;
    }
  }, []);

  const getMerchantTransaction = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/user/v1/TransactionCtrl/merchantTransactions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${userState.token}`,
        },
        redirect: "follow",
        body: JSON.stringify({
          merchantId: userState.merchantId,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        console.log(response);
        if (response.code === 200) {
          setTranscationList(response.result);
        } else {
          setTranscationList([]);
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err While Fetching Data", err);
        setLoader(false);
      });
  };

  const goToDetails = (transaction) => {
    navigate("/dashboard/transaction-details", {
        state: transaction,
      });
  } 

  return (
    <div className="outletPadding">
      <Container>
        <Row>
          {loader ? (
            <Spinner />
          ) : (
            <div className="deal-container">
              <div className="transaction-card-container">
                {transactionList.length > 0 ? (
                  transactionList.map((row, index) => (
                    <Col xs={12} md={6} lg={4} key={index}>
                        <Card className="transaction-card" key={index}>
                    <Card.Body className="d-flex flex-row">
                        <div className="image-container">
                            <Card.Img variant="top" src={row.image[0]} />
                        </div>
                        <div className="card-content">
                            <Card.Title>{row.fullName.trim()}</Card.Title>
                            <Card.Text>Transaction on: {moment(row.updatedAt).format("DD-MMM-YYYY hh:mm a")}</Card.Text>
                        </div>
                    </Card.Body>
                    <Button className="primaryBtn" variant="primary" onClick={ () => goToDetails(row) }>
                        View Transaction Details
                    </Button>
                </Card>
                    </Col>
                  ))
                ) : (
                  <center>
                    <h2>No data found</h2>
                  </center>
                )}
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default MerchantTransactions;
