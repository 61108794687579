import React, { useEffect, useState } from "react";
import { Container, Tab, Nav, Row, Col, Form, Button } from "react-bootstrap";
import QrScanner from "react-qr-scanner";
import { useNavigate } from "react-router-dom";
import { setActiveKey } from "../Common/ActiveKeySlice";

const ScanNewDeal = () => {
  const [scanResult, setScanResult] = useState("");
  const [dealCode, setdealCode] = useState("");
  const [key, setKey] = useState("ScanQr");
  const [scanWidth, setScanWidth] = useState("40%");
  const [isFrontCamera, setIsFrontCamera] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setScanWidth("98%"); // Adjust colspan for mobile
      } else {
        setScanWidth("40%"); // Adjust colspan for desktop
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();

  const handleScan = (data) => {
    if (data) {
      console.log(data);
      setScanResult(data.text);
      navigate("/dashboard/avail-deal", {
        state: data.text,
      });
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const onTabchange = (k) => {
    setKey(k);
  };

  const handleDeal = () => {
    console.log(dealCode);
    if (dealCode) {
      navigate("/dashboard/avail-deal", {
        state: dealCode,
      });
    }
  };

  const toggleCamera = () => {
    setIsFrontCamera(!isFrontCamera);
  };

  const videoConstraints = {
    facingMode: isFrontCamera ? "user" : "environment",
  };

  return (
    <div className="outletPadding">
      <Container>
        <Row className="justify-content-center mt-4">
          <Col className="tab">
            <Tab.Container activeKey={key} onSelect={(k) => onTabchange(k)}>
              <Row className="flex-column align-items-center">
                <Col xs={12} className="scan-pills d-flex justify-content-end">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item>
                      <Nav.Link eventKey="ScanQr">Scan QR</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="EnterCode">Enter Code</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>

                <Col className="mt-4">
                  <Tab.Content>
                    <Tab.Pane eventKey="ScanQr">
                      <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
                        <h2>QR Code Scanner</h2>
                        <QrScanner
                          delay={300}
                          onError={handleError}
                          onScan={handleScan}
                          style={{ width: scanWidth }}
                          constraints={{ video: videoConstraints }}
                        />
                        <button
                          className="btn btn-primary mt-2 switch-camera-button"
                          onClick={toggleCamera}
                        >
                          Switch Camera
                        </button>
                        <p>
                          {scanResult
                            ? `Scanned result: ${scanResult}`
                            : "No result yet"}
                        </p>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="EnterCode">
                      <div className="mt-2 d-flex flex-column justify-content-center align-items-center">
                        <h4>Enter Deal Code</h4>
                        <div className="m-2 d-flex flex-column">
                          <Form.Control
                            placeholder="Enter Deal Code"
                            value={dealCode}
                            onChange={(e) => {
                              if (e.target.value.match(/^[A-Z0-9]+$/)) {
                                setdealCode(e.target.value);
                              } else if (e.target.value.length === 0) {
                                setdealCode(e.target.value);
                              }
                            }}
                          />
                          <Button
                            className="primaryBtn mt-2"
                            onClick={() => handleDeal()}
                          >
                            Get Deal
                          </Button>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ScanNewDeal;
