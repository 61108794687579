import { combineReducers, configureStore } from '@reduxjs/toolkit';
import LoginReducer from '../Features/Auth/LoginSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import sidebarReducer from '../Features/Common/ActiveKeySlice';

const reducers = combineReducers({
  merchantLogin: LoginReducer,
  surveyActiveData : sidebarReducer
})

const rootReducer = (state, action) => {
    if(action.type === "userData/setLogout"){
      storage.removeItem('persist:root')
      localStorage.clear();
      return reducers(undefined, action)
    }  

    return reducers(state, action);
}

const persistConfig = {
  key:'root',
  storage,
  // blacklist: ['surveyActiveData']
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
