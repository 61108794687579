import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import {
  Button,
  Spinner,
  Accordion,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../Auth/LoginSlice";
import {
  setActiveKey,
  setImgVerifyReport,
} from "../Common/ActiveKeySlice";
import Refresh from "../../Assets/Refresh.svg";

const Menubar = () => {
  const userState = useSelector((state) => state.merchantLogin.userData.result);
  const activeKeyRedux = useSelector(
    (state) => state.surveyActiveData.activeNumberKey
  );

  const activeMenu = useSelector((state) => state.surveyActiveData?.activeMenu);

  function setnav() {
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");

    const visibility = mobilenav.getAttribute("data-visible");
    if (visibility === "false") {
      mobilenav.setAttribute("data-visible", true);
      toggle.setAttribute("aria-expanded", true);
      document.querySelector("body").style.overflow = "hidden";
    } else if (visibility === "true") {
      mobilenav.setAttribute("data-visible", false);
      toggle.setAttribute("aria-expanded", false);
      document.querySelector("body").style.overflow = "auto";
    }
  }

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(null));
    dispatch(setImgVerifyReport(null));
    localStorage.removeItem("persist:root");
    navigate("/");

    setLoader(false);
  };

  const entering = (e) => {
    e.children[0].style.borderTopColor = "white";
    e.children[1].style.backgroundColor = "white";
    e.children[0].style.borderRadius = "12px";
    e.children[1].style.borderRadius = "12px";
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
    setnav()
  };

  const Popup = (
    <Popover>
      <Popover.Body>
        <div className="PopupData">
          <div>
            <Icon.Person className="me-1" size={18} />
            <p>
              <strong>Name - </strong>
              {userState?.firstName}
            </p>
          </div>

          <div>
            <Icon.Briefcase className="me-2" size={14} />
            <p>
              <strong>Role - </strong>
              Merchant
            </p>
          </div>
          <div>
            <Icon.Telephone className="me-2" size={14} />
            <p>
              <strong>Mobile - </strong>
              {userState?.mobile}
            </p>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <nav>
      <Link className="d-flex align-items-center">
        <h6 className="mb-0 ms-2 d-block d-md-none">
          {activeKeyRedux?.activeTab}
        </h6>
        <h5 className="mb-0 ms-2 d-none d-md-block">
          {activeKeyRedux?.activeTab}
        </h5>
        <abbr title="Refresh">
          <img
            src={Refresh}
            alt="refresh"
            className="ms-2 refresh"
            style={{ width: "22px" }}
            onClick={() => {
              window.location.reload(false);
            }}
          />
        </abbr>
      </Link>

      <div className="d-flex align-items-center">
        {/* <abbr title='Refresh' className='me-2 d-block d-lg-none'>
            <img src={Refresh} alt="refresh" className='me-2 refresh' style={{width:'30px'}} onClick={()=>{ window.location.reload(false)}}/>
          </abbr> */}
        <Icon.PersonFill
          className="me-2 d-block d-lg-none"
          size={35}
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate(`/dashboard/profile`);
            dispatch(
              setActiveKey({
                activeKey: activeKeyRedux.activeKey,
                activeTab: "Profile",
              })
            );
          }}
        />

        <div
          className="mobile-nav-toggle"
          aria-controls="selectNav"
          aria-expanded="false"
          onClick={setnav}
        >
          <div className="menu-btn__burger"></div>
        </div>
      </div>

      <ul className="lg-nav">
        <OverlayTrigger
          key="bottom"
          placement="bottom"
          overlay={Popup}
          onEntering={entering}
        >
          <div
            className="d-flex"
            style={{ border: "none", cursor: "pointer" }}
            onClick={() => {
              navigate(`/dashboard/profile`);
              dispatch(
                setActiveKey({
                  activeKey: activeKeyRedux.activeKey,
                  activeTab: "Profile",
                })
              );
            }}
          >
            <Icon.PersonFill className="me-2" size={35} />
            <div>
              <p
                style={{
                  fontWeight: "600",
                  fontSize: "15px",
                  marginBottom: "-4px",
                }}
              >
                {userState?.firstName}
              </p>
              <p style={{ fontSize: "12px", marginBottom: "0px" }}>
                Merchant
              </p>
            </div>
          </div>
        </OverlayTrigger>
   
      </ul>
 

      <ul id="selectNav" className="mobile-nav" data-visible="false">
        <div className="d-flex justify-content-between flex-column h-100">
        <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
              <Accordion.Item
                eventKey={1}
                onClick={() => {
                  handleAccordionChange(1, 1, "Transaction List");
                  navigate("/dashboard/merchant-transactions");
                }}
                className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 1 ? (
                    <Icon.BagCheckFill size={22} className="me-2" />
                  ) : (
                    <Icon.BagCheck size={22} className="me-2" />
                  )}
                  Transaction List
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(2, 2, "Scan Deals");
                  navigate("/dashboard/scan-new-deal");
                }}
                className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.BagPlusFill size={22} className="me-2" />
                  ) : (
                    <Icon.BagPlus size={22} className="me-2" />
                  )}
                  Scan Deal
                </Accordion.Header>
              </Accordion.Item>

            </Accordion>   
          <div className="px-2">
            <Button className="LogoutBtn w-100" onClick={handleLogout}>
              {loader ? (
                <Spinner
                  animation="border"
                  variant="light"
                  className="spinner"
                />
              ) : (
                <>
                  <Icon.BoxArrowLeft className="me-2" />
                  Logout
                </>
              )}
            </Button>
          </div>
        </div>
      </ul>
    </nav>
  );
};

export default Menubar;
