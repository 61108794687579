import React, { useState } from "react";
import { Accordion, Button, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../Auth/LoginSlice";
import {
  setActiveKey,
  setImgVerifyReport,
} from "../Common/ActiveKeySlice";
import PMC from "../../Assets/PMC-LOGO.png";

const Sidebar = () => {
  const userState = useSelector((state) => state.merchantLogin.userData.result);
  const activeKeyRedux = useSelector(
    (state) => state.surveyActiveData?.activeNumberKey
  );

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout());
    dispatch(setActiveKey(null));
    dispatch(setImgVerifyReport(null));
    navigate("/");
    // storage.removeItem('persist:root')
    setLoader(false);
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column">
        <div>
          <div className="logoBg mb-4">
            <img src={PMC} alt="PCMC Survey" width={70} />
            <hr />
          </div>

            <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
              <Accordion.Item
                eventKey={1}
                onClick={() => {
                  handleAccordionChange(1, 1, "Transaction List");
                  navigate("/dashboard/merchant-transactions");
                }}
                className={activeKeyRedux?.activeKey === 1 ? " activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 1 ? (
                    <Icon.BagCheckFill size={22} className="me-2" />
                  ) : (
                    <Icon.BagCheck size={22} className="me-2" />
                  )}
                  Transaction List
                </Accordion.Header>
              </Accordion.Item>

              <Accordion.Item
                eventKey={2}
                onClick={() => {
                  handleAccordionChange(2, 2, "Scan Deals");
                  navigate("/dashboard/scan-new-deal");
                }}
                className={activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""}
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 2 ? (
                    <Icon.BagPlusFill size={22} className="me-2" />
                  ) : (
                    <Icon.BagPlus size={22} className="me-2" />
                  )}
                  Scan Deal
                </Accordion.Header>
              </Accordion.Item>

            </Accordion>
        </div>


        <div className="pb-4 ps-2">
          <Button className="LogoutBtn w-100" onClick={handleLogout}>
            {loader ? (
              <Spinner animation="border" variant="light" className="spinner" />
            ) : (
              <>
                <Icon.BoxArrowLeft size={18} className="me-2" />
                Logout
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
