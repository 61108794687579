import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Features/Common/ScrollToTop";
import Login from "./Features/Auth/Login";
import Profile from "./Features/Common/Profile";
import CommonLayout from "./Layout/CommonLayout";
import PageNotFound from "./Features/Common/PageNotFound";
import PrivacyPolicy from "./Features/Common/PrivacyPolicy";
import ScanNewDeal from "./Features/Admin/ScanNewDeal";
import AvailDeal from "./Features/Admin/AvailDeal";
import MerchantTransactions from "./Features/Admin/MerchantTransactions";
import ViewTransactionDetails from "./Features/Admin/ViewTransactionDetails";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Login />} />

          <Route path="/dashboard" element={<CommonLayout />}>
            <Route path="/dashboard/profile" element={<Profile />} />
            <Route
              path="/dashboard/merchant-transactions"
              element={<MerchantTransactions />}
            />
            <Route
              path="/dashboard/transaction-details"
              element={<ViewTransactionDetails />}
            />
            <Route path="/dashboard/scan-new-deal" element={<ScanNewDeal />} />
            <Route path="/dashboard/avail-deal" element={<AvailDeal />} />
          </Route>

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
