import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Spinner, Button,Card,Col } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Skeleton from "../Common/Skeleton";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment/moment";

const ViewTransactionDetails = () => {
  const userState = useSelector(
    (state) => state?.merchantLogin.userData.result
  );
  const navigate = useNavigate();
  const location = useLocation();
  const transactionData = location.state;
  const [dealData, setDealData] = useState({});
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [loader, setLoader] = useState(false);
  const isCalledRef = useRef(false);

  useEffect(() => {
    if (!isCalledRef.current) {
      getDealDetails();
      isCalledRef.current = true;
      console.log(transactionData);
    }
  }, [transactionData]);

  const getDealDetails = async () => {
    setLoader(true);
    await fetch(
      process.env.REACT_APP_BASE_URL + "/user/v1/getUserCartByCartId",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: transactionData.userId,
          cartId: transactionData.cartId,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("Deal Data", response);
        if (response.code === 200) {
            let discount = 0;
            response.result.data.map((row) => {
              discount += row.loyaltySpend * 1;
            });
            setTotalDiscount(discount)
          setDealData(response.result);
        } else if (response.code === 500) {
          toast.error("Invalid QR or Deal Code");
          navigate("/dashboard/scan-new-deal");
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log("Err While making request", err);
        setLoader(false);
      });
  };

  return (
    <div className="outletPadding">
      <Container>
        <Row>
          <h4>TrasnSaaction Details:</h4>
        </Row>

        <Row>
            <Col className="mt-3 mb-3" md={3}>
            <Button className="primaryBtn" onClick={ () => window.history.back() }>
                Back
            </Button>
            </Col>
        </Row>

        {loader ? (
          <Spinner />
        ) : (
          <div className="deal-container">
            <Card className="mb-3" style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title className="mb-3">Customer Name - <strong>{transactionData.fullName}</strong></Card.Title>
                <Row>
                  <Col xs={6}>
                    <div>
                      <strong>Transaction Date:</strong> {moment(transactionData.updateAt).format("DD-MMM-YYY hh:mm a")}
                    </div>
                    <div>
                      <strong>Quantity:</strong> {dealData.data ? dealData.data.length : '0'}
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div>
                      <strong>Final Price:</strong> ₹{dealData.final_price ?? '0'}
                    </div>
                    <div>
                      <strong>Discount:</strong> ₹{totalDiscount}
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <div className="d-flex align-items-center justify-content-center">
          <h5>Items List</h5>
        </div>
            <div className="product-list">
              {dealData?.data && dealData.data.length > 0 ? (
                dealData.data.map((row, index) => (
                  <div className="product-card" key={index}>
                    <img
                      src={row.image[0]}
                      alt={row.en.productName}
                      className="product-image"
                    />
                    <div className="product-info">
                      <h3 className="product-name">{row.en.productName}</h3>
                      <div className="price-details">
                        <span className="price">₹{row.actualAmount}</span>
                        {/* <span className="actual-amount">₹{row.price}</span> */}
                      </div>
                      {/* <div className="discount">Save ₹{row.saveAmount}</div> */}
                    </div>
                  </div>
                ))
              ) : (
                <p>No data found</p>
              )}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default ViewTransactionDetails;
