import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    activeNumberKey: { activeKey: 1, subActivekey: 1, activeTab: 'Dashboard' },
    activeImgVerifyReport : { mahilaName :'', toggle:'true' }
};

const sidebarSlice = createSlice({
  name: 'activeKey',
  initialState,
  reducers:{
    setActiveKey(state, action){
      state.activeNumberKey = {...state.activeNumberKey, ...action.payload}
    },
    setImgVerifyReport(state, action){
      state.activeImgVerifyReport = { ...state.activeImgVerifyReport, ...action.payload };
    },
  },
});

export const {setActiveKey, setImgVerifyReport,setActiveMenu,setNewActiveKey } = sidebarSlice.actions;
export default sidebarSlice.reducer;